<div class="profile-panel text-center card shadow">
    <button type="button" class="btn-close profile-panel__close p-2" aria-label="Close" (click)="close()">
        <span aria-hidden="true"></span>
    </button>
    <div class="card-body pt-1">
        <fa-icon class="profile-panel__user" icon="user-circle"></fa-icon>
        <div class="h6">
            {{ currentUser.name }}
        </div>
        <div class="text-secondary">
            <p class="mb-2">
                User ID: {{ currentUser.id }}
            </p>
            <p class="mb-2 text-truncate">
                {{ currentUser.email }}
            </p>
            <p class="mb-2 text-truncate">
                {{ timeZone }} : {{ timeOffset }}
            </p>
        </div>
        <div class="card-hdr justify-content-center">
            <span class="nav-link text-danger cursor-pointer"
                  (click)="logout()"
            >
                Sign Out
            </span>
        </div>
    </div>
</div>
