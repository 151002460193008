<div>
    <p class="h6 text-center">
        PASSWORD RESET
    </p>
    <p class="text-center">
        Fill with your mail to receive instructions on how to reset your password.
    </p>
    <form novalidate [formGroup]="form"
          [ngClass]="{'was-validated' : fb.isSubmitted()}"
          (ngSubmit)="tryRecover()"
    >
        <div class="mb-3">
            <label class="form-label fw-bold" for="username">
                Username <sup class="text-danger">*</sup>
            </label>
            <input type="email" id="username" class="form-control" placeholder="email"
                   formControlName="email"
                   required [minLength]="fb.minEmail" [maxLength]="fb.maxEmail">

            <app-form-control-error [control]="form.get('email')"
                                    [formSubmit]="fb.isSubmitted()"
            ></app-form-control-error>
            <div class="invalid-feedback d-block">{{form?.errors?.firebase}}</div>
        </div>
        <div class="d-grid gap-2">
            <button type="submit"
                    class="btn btn-danger mb-2"
            >
                Reset
            </button>
            <button type="submit"
                    class="btn btn-outline-secondary"
                    (click)="onOpenLoginForm()"
            >
                Sign in
            </button>
        </div>
    </form>
</div>
