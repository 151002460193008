import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Favicons } from '../services/browser-favicons.service';
import { SIDEBAR_UNFOLDABLE } from '../../domain/models/sidebar-display-types';
import { fetchAndActivate, RemoteConfig } from '@angular/fire/remote-config';
import { CookieService } from 'ngx-cookie-service';
import { DEBUG_MODE_KEY } from './app.types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{

    showMenu = true;
    showMobileMenu = false;
    sidebarUnfoldable = false;
    logged = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private favicons: Favicons,
        @Inject(DOCUMENT) private document: Document,
        private remoteConfig: RemoteConfig,
        private cookieService: CookieService,
    )
    {
        const sidebarUnfoldable = localStorage.getItem(SIDEBAR_UNFOLDABLE);

        this.sidebarUnfoldable = sidebarUnfoldable != null ? JSON.parse(sidebarUnfoldable) : false;
    }

    ngOnInit(): void
    {
        fetchAndActivate(this.remoteConfig);
        this.setXDebugToCookie();
        this.favicons.activate();
        this.router.events.pipe(
            filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0)
        ).subscribe((event: ActivationEnd) => {
            this.logged = !event.snapshot.data.unLogged;
        });
    }

    toggleMobileMenu(): void {
        this.showMobileMenu = !this.showMobileMenu;
    }

    private setXDebugToCookie(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const debugMode = urlParams.get(DEBUG_MODE_KEY);

        if (debugMode === null) {
            return;
        }

        const value = /^\d+$/.test(debugMode) ? debugMode : 0;

        this.cookieService.set(DEBUG_MODE_KEY, String(value), {
            path: '/',
        });
    }
}
