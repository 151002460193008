import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { CopyrightNotice } from '../../utils/copyright-notice';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from '../../../../shared/application/services/toastr.service';
import { DEBUG_MODE_KEY } from '../app.types';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy, AfterViewInit {
    @ViewChild('copyrightNoticeRef', { static: false }) copyrightNoticeRef: ElementRef;
    public copyrightNotice: string = CopyrightNotice.fetch('text-primary');
    private clicksCount = 0;

    public constructor(
      private cookieService: CookieService,
      private toastrService: ToastrService,
    ) {}

    ngOnDestroy(): void {
        if(this.copyrightNoticeRef?.nativeElement?.querySelector('[data-copyright-build]')){
            this.copyrightNoticeRef.nativeElement.querySelector('[data-copyright-build]').removeEventListener('click', this.clearCache.bind(this));
        }
    }

    ngAfterViewInit(): void {
        if(this.copyrightNoticeRef?.nativeElement?.querySelector('[data-copyright-build]')){
            this.copyrightNoticeRef.nativeElement.querySelector('[data-copyright-build]').addEventListener('click', this.clearCache.bind(this));
        }
    }

    private clearCache(): void {
        this.clicksCount++;

        if (this.clicksCount % 2 === 0) {
            this.clearDebugMode();
            // clear cache by HTML5 API
            self.caches.keys().then((keys) => {
                keys.forEach((key) => self.caches.delete(key));
            });
            // Avoid use old JS version files
            (function () {
                const rep = /.*\?.*/;
                const links = document.getElementsByTagName('link');
                const scripts = document.getElementsByTagName('script');

                for (let i = 0; i < links.length; i++) {
                    const link = links[i];
                    const href = link.href;
                    if (rep.test(href)) {
                        link.href = href + '&' + Date.now();
                    } else {
                        link.href = href + '?' + Date.now();
                    }
                }

                for (let i = 0; i < scripts.length; i++) {
                    const script = scripts[i];
                    const src = script.src;
                    if (rep.test(src)) {
                        script.src = src + '&' + Date.now();
                    } else {
                        script.src = src + '?' + Date.now();
                    }
                }
            })();
            this.toastrService.successNotify('Cache cleared! Gonna reload page in 3 seconds.');
            // Reload page without cache
            // Not worked in Chrome, use window.location.href = window.location.href
            setTimeout(() => location.reload(), 3000);
        }
    }

    private clearDebugMode(): void {
        this.cookieService.delete(DEBUG_MODE_KEY, '/');
        window.localStorage.removeItem(DEBUG_MODE_KEY);
    }
}
