<app-toastr-container aria-live="polite" aria-atomic="true"></app-toastr-container>
<ng-template appConfirm>
    <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>

<div class="page-inner">
<!--    TODO revert before release epic-->
<!--     [ngClass]="getThemClass">-->
    <app-sidebar *ngIf="logged"
                 class="sidebar sidebar-dark sidebar-fixed bg-gradient-brand bg-gradient-brand--v"
                 [showMenu]="showMenu"
                 [showMobileMenu]="showMobileMenu"
                 [sidebarUnfoldable]="sidebarUnfoldable"
                 (hideSidebar)="toggleMobileMenu()"></app-sidebar>

    <div class="page-content-wrapper"
         [ngClass]="{ 'bg-gradient-brand bg-gradient-brand--h bg-secondary': !logged }">

        <app-header class="page-header"
                    [(sidebarUnfoldable)]="sidebarUnfoldable"
                    [logged]="logged"
                    (toggleMobileNav)="toggleMobileMenu();"
        ></app-header>

        <main class="page-content" role="main"
              [class.background]="!logged">
            <router-outlet></router-outlet>
        </main>
        <div class="page-content-overlay"
             *ngIf="logged"
             (click)="toggleMobileMenu()"
        ></div>

        <app-footer class="page-footer" *ngIf="logged"></app-footer>
    </div>

    <div class="sidebar-backdrop fade show"
         *ngIf="showMobileMenu"
         (click)="toggleMobileMenu()"
    ></div>
</div>
