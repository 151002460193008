import { JsonPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { Component, EventEmitter, inject, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Auth } from "@angular/fire/auth";
import { Database, ref, onValue, update, push, child, remove } from "@angular/fire/database";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { DEFAULT_ROUTE } from "../../../../../constants/default-route";
import { CopyCellComponent } from "../../../../shared/application/components/copy-cell/copy-cell.component";
import { FindInListInterface } from "../../../../shared/application/components/tables/th/th.component";
import { OrderByPipe } from "../../../../shared/application/pipes/order-by/order-by.pipe";
import { ToastrService } from "../../../../shared/application/services/toastr.service";
import { SharedModule } from "../../../../shared/shared.module";
import { CoreService } from "../../services/core.service";
import { DateUtil } from "../../utils/date/date.util";

enum NotificationEnum
{
    b2b_hyperlocal_orders = 'New b2b hyperlocal orders',
    // 'upload_master_price_list'= 'Upload master price list',
}

type NotificationEnumType = keyof typeof NotificationEnum;

interface Notification
{
    createdAt: number;
    orderNumber: string;
    read: boolean;
    key?: string;
}

@Component({
    selector: 'app-notifications-panel',
    standalone: true,
    imports: [
        JsonPipe,
        NgIf,
        FormsModule,
        NgForOf,
        SharedModule,
        RouterLink,
        CopyCellComponent,
        NgClass
    ],
    templateUrl: './notifications-panel.component.html',
    styleUrl: './notifications-panel.component.scss'
})

export class NotificationsPanelComponent implements OnInit, OnChanges
{
    @Output() closePanel = new EventEmitter();
    @Output() unreadCount = new EventEmitter<number>();
    notificationsMap: Record<string, Notification> = {};
    channelList: FindInListInterface[] = Object.keys(NotificationEnum).map((id) => ({
        name: NotificationEnum[id],
        id
    }));
    activeChannel: NotificationEnumType = this.channelList[0].id;
    readonly ordersRoute = DEFAULT_ROUTE.sales.orders;
    private db: Database = inject(Database);
    private uid: string;

    constructor(
        private auth: Auth,
        private coreService: CoreService,
        public toastrService: ToastrService,
    )
    {
        this.uid = this.auth?.currentUser?.uid;
    }

    get dbRef()
    {
        return ref(this.db, `/${this.activeChannel}/${this.userId}/`);
    }

    get userId()
    {
        return this.auth.currentUser?.uid || this.uid;
    }

    // TODO unused can be removed
    get title()
    {
        return this.channelList[this.activeChannel];
    }

    get notifications(): Required<Notification>[]
    {
        return new OrderByPipe().transform(Object.keys(this.notificationsMap).reduce((acc, key) => {
            const notification = this.notificationsMap[key];
            notification.key = key;
            acc.push(notification);
            return acc;
        }, []), ['read', 'createdAt'], ['asc', 'desc']);
    }

    get unreadNotifications(): Required<Notification>[]
    {
        return this.notifications.filter((notification) => !notification.read);
    }

    get unreadNotificationsForCopy(): string
    {
        return this.unreadNotifications.map((notification) => notification.orderNumber).join('\n');
    }

    ngOnInit()
    {
        setTimeout(() => {
            onValue(this.dbRef, (snapshot) => {
                this.notificationsMap = snapshot.val() || {};

                // Automatically remove notifications older than 14 days
                const LIFE_DAYS = 14;
                for (const notification of this.notifications) {
                    if (DateUtil.datesComparison('before', DateUtil.addDays(new Date(notification.createdAt * 1000), LIFE_DAYS), new Date())) {
                        this.removeNotification(notification.key);
                    }
                }
                this.unreadCount.emit(this.unreadNotifications.length);
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (
            changes.logged !== undefined &&
            changes.logged.previousValue !== undefined &&
            changes.logged.currentValue !== changes.logged.previousValue
        ) {
            this.uid = this.auth.currentUser.uid;
        }
    }

    addNotification(orderNumber: string)
    {
        // Get a key for a new Post.
        const newPostKey = push(this.dbRef).key;

        // Write the new post's data simultaneously in the posts list and the user's post list.
        update(this.dbRef, {
            [newPostKey]: {
                createdAt: Math.ceil(Date.now() / 1000),
                orderNumber,
                read: false,
            }
        });
    }

    removeNotification(key?: string)
    {
        if (!key) remove(this.dbRef);
        else remove(child(this.dbRef, key));
    }

    toggleNotification(_key: string, read?: boolean)
    {
        const {key, ...notification} = this.notificationsMap[_key];
        notification.read = Boolean(read);
        update(this.dbRef, {
            [_key]: notification
        });
    }

    copyUnreadNotificationsAndRead()
    {
        setTimeout(() => {
            this.unreadNotifications.forEach((notification) => this.toggleNotification(notification.key, true));
        });
    }

    trackByFn = (index: number, item: Notification): string => this.coreService.trackByFn(index, item, 'key');
}
