<!--<footer role="contentinfo">-->
    <div class="d-flex align-items-center flex-1 text-muted">
        <span class="d-none d-md-block" #copyrightNoticeRef [innerHTML]="copyrightNotice|safeHtml">
        </span>
    </div>
<!--    <div>-->
<!--        <ul class="list-table m-0">-->
<!--            <li class="ps-3">-->
<!--                <a href="javascript:void(0);" class="text-secondary fw-bolder">-->
<!--                    License-->
<!--                </a>-->
<!--            </li>-->
<!--            <li class="ps-3">-->
<!--                <a href="javascript:void(0);" class="text-secondary fw-bolder">-->
<!--                    Documentation-->
<!--                </a>-->
<!--            </li>-->
<!--        </ul>-->
<!--    </div>-->
<!--</footer>-->
