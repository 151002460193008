import { BUILD, VERSION } from '../../../../environments/version';

export class CopyrightNotice
{
    private year = (new Date()).getFullYear();
    private placeholder = `${this.year} © Marketplace Admin by&nbsp;<a href="https://boodmo.com" class="%linkClass% fw-500" title="boodmo.com"
    target="_blank">boodmo.com</a> (${VERSION} build <span data-copyright-build>${BUILD}</span>)`;

    public static fetch = (className: string): string => new CopyrightNotice().placeholder.replace(/%linkClass%/, className);
}
