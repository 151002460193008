<div class="profile-panel card shadow">
    <div class="card-hdr d-flex justify-content-between align-items-center pe-0">
        <h1 class="card-title">
            Info Panel
        </h1>
        <button type="button" class="btn-close profile-panel__close p-2" aria-label="Close" (click)="closePanel.emit()">
            <span aria-hidden="true"></span>
        </button>
    </div>
    <div class="card-body pt-1 profile-panel__fixed">
        <h5 *ngIf="title" class="card-title fs-6">{{title}}</h5>
        <pre *ngIf="debugInfo" class="mb-0">{{debugInfo | json}}</pre>
    </div>
</div>
