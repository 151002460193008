import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DebugStorageService } from '../../services/debug-storage.service';

@Component({
    selector: 'app-debug-panel',
    templateUrl: './debug-panel.component.html',
    styleUrls: ['./debug-panel.component.scss']
})
export class DebugPanelComponent implements OnDestroy
{
    @Output() closePanel = new EventEmitter();
    subscription: Subscription;
    debugInfo: Record<any, any>;
    title?: string;

    constructor(
        public storage: DebugStorageService,
    )
    {
        this.subscription = this.storage.debugInfo$.subscribe(({data, openPanel, title}) => {
            this.debugInfo = data;
            this.title = title;
        });
    }

    ngOnDestroy(): void
    {
        this.subscription?.unsubscribe();
    }
}
