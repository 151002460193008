import { Injectable } from '@angular/core';
import { Auth, onAuthStateChanged, User } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from "@angular/router";
import { from, mergeMap, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DEFAULT_ROUTE } from "../../../../constants/default-route";
import { AdminGroupIdEnum } from "../../../system/domain/models/admin-role";
import { AuthService, CurrentUserInterface } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    private isFirebaseInit = new ReplaySubject(1);
    constructor(
        private authService: AuthService,
        private auth: Auth,
        private router: Router,
        )
    {
        onAuthStateChanged(
            this.auth,
            (user: User) => {
                this.authService.currentUserSource.next(user as CurrentUserInterface);
                this.isFirebaseInit.next(true);
            },
            () => this.isFirebaseInit.next(true),
            () => this.isFirebaseInit.next(true)
        );
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): MaybeAsync<GuardResult>
    {
        return this.isFirebaseInit.pipe(
            take(1),
            mergeMap(() =>  from(
                (async () => {
                    const redirectToAccessDenied = () => this.router.navigate([DEFAULT_ROUTE.accessDenied]);

                    try {
                        const userRole = await this.authService.getCurrentUserRole();
                        const userRoleGroupId: number = AdminGroupIdEnum[userRole] ?? AdminGroupIdEnum.null;

                        const priorityRoutesMap = {
                            admin: [
                                '/system/users',
                            ]
                        }
                        let hasAccess = true;
                        for (const priorityRoutesMapKey in priorityRoutesMap) {
                            const priorityRoutes = priorityRoutesMap[priorityRoutesMapKey];
                            if (priorityRoutes.includes(state.url)) {
                                hasAccess = userRoleGroupId <= AdminGroupIdEnum[priorityRoutesMapKey];
                                break;
                            }
                        }

                        if (!hasAccess) await redirectToAccessDenied();
                        return hasAccess;
                    } catch (e) {
                        await this.authService.logout();
                        return false;
                    }
                })()
            ))
        );
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): MaybeAsync<GuardResult>
    {
        return this.canActivate(childRoute, state);
    }
}
