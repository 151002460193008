import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecoverForm } from '../../../../forms/recover.form';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';

@Component({
    selector: 'app-recover-form',
    templateUrl: './recover-form.component.html',
    styleUrls: ['./recover-form.component.scss']
})
export class RecoverFormComponent implements OnInit {
  @Output() openLoginForm = new EventEmitter();
  @Output() setEmail = new EventEmitter<string>();
  @Input() email: string;
  readonly fb = new RecoverForm();
  readonly form = this.fb.getForm();
  loading: Subscription | boolean;

  /**
   * @constructor
   */
  constructor(
      private authService: AuthService
  ) {}

  /**
   * @implements OnInit
   */
  ngOnInit(): void {
    this.setRecoverEmail();
  }

    async tryRecover()
    {
        this.fb.markSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        try {
            await this.authService.sendPasswordResetEmail(this.form.value.email);
            this.openLoginForm.emit();
            this.setEmail.emit(this.form.value.email);
        } catch (e) {
            this.form.setErrors({firebase: e.message});
        } finally {
            this.loading = false;
        }
    }

  /**
   * @description onOpenLoginForm
   */
  public onOpenLoginForm(): void {
    this.openLoginForm.emit();
  }

  private setRecoverEmail(): void {
    this.form.patchValue({
        email: this.email,
      }
    );
  }
}
