import { Component, OnDestroy } from '@angular/core';
import { AuthService } from "../../services/auth.service";

@Component({
    selector: 'app-access-denied',
    standalone: true,
    imports: [],
    templateUrl: './access-denied.component.html',
    styleUrl: './access-denied.component.scss'
})
export class AccessDeniedComponent implements OnDestroy
{
    constructor(
        private authService: AuthService
    )
    {
    }
    ngOnDestroy()
    {
        this.logout();
    }

    logout()
    {
        this.authService.logout();
    }
}
