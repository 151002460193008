import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DEFAULT_ROUTE } from '../../constants/default-route';
import { AccessDeniedComponent } from "./application/components/access-denied/access-denied.component";
import { LoginComponent } from './application/components/login/login.component';
import { AccessGroupGuard } from "./application/guards/access-group.guard";
import { AuthGuard } from './application/guards/auth.guard';


export const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: {unLogged: true}
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent,
        data: {unLogged: true}
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../shared/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: 'crm',
        loadChildren: () => import('../crm/crm.module').then(m => m.CrmModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: 'sales',
        loadChildren: () => import('../sales/sales.module').then(m => m.SalesModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: 'logistic',
        loadChildren: () => import('../logistic/logistic.module').then(m => m.LogisticModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: 'srm',
        loadChildren: () => import('../srm/srm.module').then(m => m.SrmModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: 'pim',
        loadChildren: () => import('../pim/pim.module').then(m => m.PimModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: 'system',
        loadChildren: () => import('../system/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: 'finance',
        loadChildren: () => import('../finance/finance.module').then(m => m.FinanceModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: 'billing',
        loadChildren: () => import('../billing/billing.module').then(m => m.BillingModule),
        canActivate: [AuthGuard, AccessGroupGuard],
        canActivateChild: [AuthGuard, AccessGroupGuard],
    },
    {
        path: '**',
        redirectTo: DEFAULT_ROUTE.loggedIn
    },
    {
        path: '',
        redirectTo: DEFAULT_ROUTE.loggedIn,
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, AccessGroupGuard]
})

export class CoreRoutingModule
{
}
