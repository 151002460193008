import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Auth, getIdTokenResult } from "@angular/fire/auth";
import { getString, RemoteConfig } from "@angular/fire/remote-config";
import { Router } from '@angular/router';
import { APP_LINK_MENU, AppMenuItemInterface } from '../../../../../constants/app-link-menu';
import { SettingService } from '../../../../system/application/services/setting.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SettingSectionsListResponse } from '../../../../system/infrastructure/response/setting/setting-sections-list.response';
import { AuthService } from "../../services/auth.service";
import _cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, OnChanges {
    @Output() hideSidebar = new EventEmitter();
    @HostBinding('class.sidebar-lg-show') @Input() showMenu = true;
    @HostBinding('class.sidebar-show') @Input() showMobileMenu = false;
    @HostBinding('class.sidebar-unfoldable') @Input() sidebarUnfoldable = false;

    navItems: AppMenuItemInterface[] = _cloneDeep(APP_LINK_MENU);
    settingRequestSubscription: Subscription;
    settingSectionsListSubscription: Subscription;
    settingSectionsLoading: boolean;
    settingSectionsList: SettingSectionsListResponse[] = [];
    currentUser: any;

    constructor(
        private router: Router,
        private settingService: SettingService,
        private remoteConfig: RemoteConfig,
        private auth: Auth,
        private authService: AuthService,
        )
    {}

    ngOnInit(): void {
        this.settingSectionsObservable();
        this.setNavItems();
    }

    ngOnDestroy(): void {
        this.settingRequestSubscription?.unsubscribe();
        this.settingSectionsListSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.currentUser !== undefined &&
            changes.currentUser.previousValue !== undefined &&
            changes.currentUser.currentValue !== changes.logged.previousValue
        ) {
            this.setNavItems();
        }
    }

    isLinkActive(item: AppMenuItemInterface): boolean {
        const queryParamsIndex = this.router.url.indexOf('?');
        const baseUrl = queryParamsIndex === -1 ?
            this.router.url :
            this.router.url.slice(0, queryParamsIndex);
            const route = baseUrl.split('/').splice(0, 3).join('/');

        if (item.child?.length && !item?.toggled) {
            return item.child.some((secondLevel: AppMenuItemInterface ) => {
                if (route === secondLevel.route) {
                    return true;
                } else if (secondLevel.child?.length && !secondLevel?.toggled) {
                    return  secondLevel.child.some((thirdLevel: AppMenuItemInterface ) => route === thirdLevel.route);
                }
            });
        }

        return baseUrl === item.route;
    }

    toggleFirstLevel(firstLevel: AppMenuItemInterface) {
        this.navItems.forEach((i) => {
            i.toggled = i === firstLevel ? !firstLevel.toggled : false;
        });

        if (firstLevel.child?.length) {
            firstLevel.child.forEach((secondLevel: AppMenuItemInterface ) => secondLevel.toggled = false);
        }
    }

    toggleSecondLevel(secondLevel: AppMenuItemInterface, firstLevel: AppMenuItemInterface) {
        firstLevel.child.forEach((i) => {
            i.toggled = i === secondLevel ? !secondLevel.toggled : false;
        });

        if (secondLevel.title === 'Settings') {
            this.settingSectionRequest();
        }
    }

    private settingSectionRequest(): void {
        if (this.settingSectionsList.length) {
            return;
        }

        this.settingSectionsLoading = true;
        this.settingRequestSubscription = this.settingService.fetchSettingSectionsList().subscribe();
    }

    private settingSectionsObservable(): void {
        this.settingSectionsListSubscription = this.settingService.settingSectionsList$
          .pipe(
            finalize(() => this.settingSectionsLoading = false),
          )
          .subscribe((response) => {
              this.settingSectionsLoading = false;
              this.settingSectionsList = response;
              this.addSettingInAppLinkMenu();
          });
    }

    private addSettingInAppLinkMenu(): void {
        for (const i in this.navItems) {
            if (this.navItems[i].title === 'General') {
                for (const k in this.navItems[i].child) {
                    if (this.navItems[i].child[k].title === 'Settings') {
                        this.navItems[i].child[k].child = this.settingSectionsList.map(item => ({
                            title: item.label,
                            route: `/system/settings/${item.path}`,
                        }));
                        break;
                    }
                }
                break;
            }
        }
    }

    private getAccessGroupConfig()
    {
        const dataString = getString(this.remoteConfig, 'access_group_config');
        if (!dataString) return;
        return JSON.parse(dataString);
    }

    private async setNavItems() {
        try {
            this.currentUser = this.auth.currentUser;
            const {claims} = await getIdTokenResult(this.currentUser);
            const useAccessGroup = 'accessGroup' in claims;
            this.navItems = _cloneDeep(APP_LINK_MENU);
            if (!useAccessGroup) return;
            const accessConfig = this.getAccessGroupConfig();
            const availableRoutes = accessConfig?.[claims?.accessGroup as string]?.routes;
            this.navItems = this.navItems.filter((item) => {
                if (item.child?.length) {
                    item.child = item.child.filter((secondLevel) => availableRoutes.includes(secondLevel.route));
                }
                return availableRoutes.includes(item.route) || item.child?.length;
            });
        } catch (e) {
            this.authService.logout();
        }
    }
}
