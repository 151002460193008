<div class="profile-panel card shadow-lg">
    <div class="card-hdr justify-content-between">
        <h1 class="card-title">
            Notifications
            <button class="btn btn-sm btn-light ms-1 position-relative"
                    ngbTooltip="Copy unread notifications to clipboard and mark as read"
                    *ngIf="unreadNotifications.length as unreadCount"
                    [appCopyClipboard]="unreadNotificationsForCopy"
                    (copied)="toastrService.successNotify('Unread notifications copied to clipboard and marked as read')"
                    (click)="copyUnreadNotificationsAndRead()"
            >
                <fa-icon [icon]="['fas', 'list-check']"></fa-icon>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                >{{unreadCount}}</span>
            </button>
        </h1>
<!--        <button (click)="removeNotification()"-->
<!--                class="btn btn-sm btn-danger me-4"-->
<!--                ngbTooltip="Remove all notifications"-->
<!--        >-->
<!--            <fa-icon [icon]="['fas', 'trash']"></fa-icon>-->
<!--        </button>-->

        <button type="button" class="btn-close profile-panel__close p-2" aria-label="Close" (click)="closePanel.emit()">
            <span aria-hidden="true"></span>
        </button>
    </div>
    <ul class="list-group list-group-flush">
        <li class="list-group-item list-group-item-action">
            <select class="form-select form-select-sm"
                    [(ngModel)]="activeChannel">
                <option *ngFor="let o of channelList" [value]="o.id">{{o.name}}</option>
            </select>
        </li>
<!--        <li class="list-group-item list-group-item-action">-->
<!--            <input #newNumber-->
<!--                   placeholder="Add new order number"-->
<!--                   type="text" id="orderNumber"-->
<!--                   class="form-control form-control-sm"-->
<!--                   [(ngModel)]="newNumber.value"-->
<!--                   (keydown.enter)="addNotification(newNumber.value); newNumber.value = ''"-->
<!--            />-->
<!--        </li>-->
    </ul>
    <div class="card-body pt-1 profile-panel__fixed">
        <ul class="list-group list-group-flush">
            <li class="list-group-item list-group-item-action"
                *ngFor="let notification of notifications; trackBy:trackByFn"
                [class.item-row-disabled]="notification.read"
            >
                <div class="d-flex w-100 justify-content-between align-items-start">
                    <div [ngClass]="{'fw-bold': !notification.read}">
                        <a [routerLink]="[ordersRoute, notification.orderNumber]"
                           [ngbTooltip]="'Open ' + notification.orderNumber + ' details in new tab'"
                           target="_blank">
                            {{ notification.orderNumber }}
                        </a>
                        <app-copy-cell [data]="notification.orderNumber" [iconOnly]="true"/>
                        <small class="text-body-secondary d-block">{{notification.createdAt * 1000 | bdate: 'dd-MM-yyyy HH:mm:ss'}}</small>
                    </div>
                    <div class="btn-group btn-group-sm">
<!--                        <button class="btn btn-outline-danger"-->
<!--                                ngbTooltip="Remove notification"-->
<!--                                (click)="removeNotification(notification.key)"-->
<!--                        >-->
<!--                            <fa-icon [icon]="['fas', 'trash']" [fixedWidth]="true"></fa-icon>-->
<!--                        </button>-->
                        <button class="btn btn-outline-primary"
                                (click)="toggleNotification(notification.key, !notification.read)"
                                [ngbTooltip]="notification.read?'Mark as unread':'Mark as read'"
                                container="body"
                        >
                            <fa-icon [icon]="notification.read?'eye':'eye-slash'" [fixedWidth]="true"></fa-icon>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
