import { Injectable } from '@angular/core';
import { AuthErrorCodes } from '@angular/fire/auth';

@Injectable({
    providedIn: 'root',
})
export class FirebaseService {
    private FirebaseErrorCodeMap = {};
    constructor()
    {
        this.FirebaseErrorCodeMap[AuthErrorCodes.ARGUMENT_ERROR] = 'Argument error.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN] = 'Please logout, re-login, and try again.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.INVALID_PASSWORD] = 'Incorrect password.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.TOKEN_EXPIRED] = 'Your token has expired.  Please logout and re-login.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.USER_CANCELLED] = 'Login process was stopped by you.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.USER_DELETED] = 'User does not exist.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.USER_DISABLED] = 'Your account has been disabled.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.USER_MISMATCH] = 'Credential given does not correspond to you.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.USER_SIGNED_OUT] = 'You are signed out.  Please re-sign in.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.WEAK_PASSWORD] = 'Your password is too weak. It must be at least six characters long.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.INVALID_EMAIL] = 'The email address is improperly formatted.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.INTERNAL_ERROR] = 'Internal Error.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.INVALID_API_KEY] = 'Invalid API key.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.INVALID_APP_CREDENTIAL] = 'Invalid app credential.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.INVALID_APP_ID] = 'Invalid app ID.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.INVALID_AUTH] = 'Invalid user token.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.TIMEOUT] = 'Authentication timeout.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.UNVERIFIED_EMAIL] = 'Your email address is not verified.  Please verify it.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.WEB_STORAGE_UNSUPPORTED] = 'Web storage is unsupported.  Please update or use a different browser.';
        this.FirebaseErrorCodeMap[AuthErrorCodes.ALREADY_INITIALIZED] = 'Already initialized.';
        this.FirebaseErrorCodeMap['auth/too-many-requests'] = `Access to this account has been temporarily disabled due to many failed login attempts.
         You can immediately restore it by resetting your password or you can try again later.`;
    }

    getMessageFromCode(code): string{
        return this.FirebaseErrorCodeMap[code] || `Unknown error >> code = ${code}`;
    }
}
