<button class="header-toggler class-toggler d-md-down-none"
        type="button"
        (click)="toggleSidebar()"
>
    <fa-icon [icon]="'bars'"></fa-icon>
<!--    <svg id="cil-menu" viewBox="0 0 24 24">-->
<!--        <title>menu</title>-->
<!--        <path d="M3.75 4.5h16.5v1.5h-16.5v-1.5z"></path>-->
<!--        <path d="M3.75 11.25h16.5v1.5h-16.5v-1.5z"></path>-->
<!--        <path d="M3.75 18h16.5v1.5h-16.5v-1.5z"></path>-->
<!--    </svg>-->
</button>

<button class="header-btn btn d-block d-lg-none" data-class="mobile-nav-on"
        (click)="toggleMobileNav.emit()"
        aria-label="Toggle navigation">
    <fa-icon [icon]="'bars'"></fa-icon>
</button>

<app-breadcrumbs *ngIf="logged"></app-breadcrumbs>

<div *ngIf="logged"
     class="d-flex"
>
    <app-export-results #exportResults
                        class="header-user"
                        (click)="toggleProfilePanel(false);toggleDebugPanel(false);toggleNotificationsPanel(false)"
    />

    <div class="header-user"
         (click)="toggleProfilePanel(false);toggleDebugPanel();toggleNotificationsPanel(false);exportResults.dropdown.close()"
    >
        <span class="header-icon"
              ngbTooltip="Click to open Info Panel"
              container="body"
        >
            <fa-icon [icon]="'file-code'"/>
        </span>
    </div>
    <div class="header-user"
         (click)="toggleProfilePanel(false);toggleDebugPanel(false);toggleNotificationsPanel();exportResults.dropdown.close()"
    >
        <span class="header-icon position-relative"
              [ngbTooltip]="'Click to open Notifications Panel' + (unreadNotificationsCount ? ' (' + unreadNotificationsCount + ' unread)' : '')"
              container="body"
        >
            <fa-icon [icon]="'bell'"/>
            <span class="position-absolute bottom-0 translate-middle badge rounded-pill bg-danger"
                  *ngIf="unreadNotificationsCount">
                {{ unreadNotificationsCount > 99 ? '99+' : unreadNotificationsCount }}
                <span class="visually-hidden">unread messages</span>
            </span>

        </span>
    </div>
    <app-inline-loader *ngIf="currentUserLoading; else headerUser"></app-inline-loader>
    <ng-template #headerUser>
        <div *ngIf="currentUser"
             class="header-user"
             (click)="toggleDebugPanel(false);toggleProfilePanel();toggleNotificationsPanel(false);exportResults.dropdown.close()"
        >
            {{ currentUser?.name }}
            <span class="header-icon"
                  container="body"
                  ngbTooltip="User Profile"
            >
                <fa-icon icon="user-circle"/>
            </span>
        </div>
    </ng-template>
</div>

<app-debug-panel
    class="header-panel"
    [class.show]="isDebugOpen"
    (closePanel)="toggleDebugPanel(false)"
/>

<app-notifications-panel
    *ngIf="currentUser"
    class="header-panel"
    [class.show]="isNotificationsOpen"
    (closePanel)="toggleNotificationsPanel(false)"
    (unreadCount)="unreadNotificationsCount = $event"
/>

<app-profile-panel *ngIf="currentUser && logged"
                   class="header-panel"
                   [class.show]="isProfileOpen"
                   (closeProfile)="toggleProfilePanel(false)"
                   [currentUser]="currentUser"
/>
