import {
    Component,
    EventEmitter,
    Input, OnChanges, OnDestroy,
    Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { Auth, getIdTokenResult } from '@angular/fire/auth';
import { AuthService } from '../../services/auth.service';
import { SIDEBAR_UNFOLDABLE } from '../../../domain/models/sidebar-display-types';
import { Subscription } from 'rxjs';
import { CurrentUserResponse } from '../../../infrastructure/response/current-user.response';
import { DebugStorageService } from '../../services/debug-storage.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnChanges, OnDestroy {
    @Input() sidebarUnfoldable: boolean;
    @Input() logged: boolean;
    @Output() sidebarUnfoldableChange = new EventEmitter<boolean>();
    @Output() toggleMobileNav = new EventEmitter();
    isProfileOpen: boolean;
    isDebugOpen: boolean;
    isNotificationsOpen: boolean;
    currentUserSubscription: Subscription;
    debugPanelSubscription: Subscription;
    currentUserLoading: boolean;
    currentUser: CurrentUserResponse;
    unreadNotificationsCount: number;

    public constructor(
        private auth: Auth,
        private authService: AuthService,
        public storage: DebugStorageService,
    )
    {
        this.debugPanelSubscription = this.storage.debugInfo$.subscribe(({data, openPanel}) => {
            if (openPanel !== undefined) {
                this.isDebugOpen = openPanel;
                this.addDebugPanelClass();
            }
        });
    }

    /**
     * @implements OnDestroy
     */
    ngOnDestroy(): void {
        this.currentUserSubscription?.unsubscribe();
        this.debugPanelSubscription?.unsubscribe();
    }

    /**
     * @implements OnChanges
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (
          changes.logged !== undefined &&
          changes.logged.previousValue !== undefined &&
          changes.logged.currentValue !== changes.logged.previousValue
        ) {
            this.currentUseRequest(changes.logged.currentValue);
        }
    }

    toggleSidebar(): void {
        this.sidebarUnfoldable = !this.sidebarUnfoldable;
        this.sidebarUnfoldableChange.emit(this.sidebarUnfoldable);
        localStorage.setItem(SIDEBAR_UNFOLDABLE, JSON.stringify(this.sidebarUnfoldable));

    }

    toggleProfilePanel(visible?: boolean): void {
        if (visible === undefined) {
            this.isProfileOpen = !this.isProfileOpen;
        } else {
            this.isProfileOpen = visible;
        }
    }

    toggleDebugPanel(visible?: boolean): void {
        if (visible === undefined) {
            this.isDebugOpen = !this.isDebugOpen;
        } else {
            this.isDebugOpen = visible;
        }
        this.addDebugPanelClass();
    }

    addDebugPanelClass(): void
    {
        document.querySelector('body').classList[this.isDebugOpen ? 'add' : 'remove']('show-debug-panel');
    }

    toggleNotificationsPanel(visible?: boolean): void {
        if (visible === undefined) {
            this.isNotificationsOpen = !this.isNotificationsOpen;
        } else {
            this.isNotificationsOpen = visible;
        }
        this.addNotificationsClass();
    }

    addNotificationsClass(): void
    {
        document.querySelector('body').classList[this.isNotificationsOpen ? 'add' : 'remove']('show-notifications-panel');
    }

    // togglePanels(type: 'export' | 'profile' | 'debug' | 'notification'): void {
    //     switch  (type) {
    //
    //     }
    // }

    private async currentUseRequest(logged: boolean)
    {
        if (!logged) return;

        this.currentUserLoading = true;
        try {
            const currentUser = this.auth.currentUser;
            const {claims} = await getIdTokenResult(currentUser);
            this.currentUser = {
                name: currentUser.displayName,
                id: claims.aid as number,
                email: currentUser.email
            };
        } catch (e) {
            this.authService.logout();
        } finally {
            this.currentUserLoading = false;
        }
    }
}
