import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AbstractForm } from './abstract.form';

export class RecoverForm extends AbstractForm
{
    public minEmail: number;
    public maxEmail: number;

    public constructor()
    {
        super();
        this.minEmail = 5;
        this.maxEmail = this.maxLength;
        this.form = new UntypedFormGroup({
            email: new UntypedFormControl('', [
                Validators.required as any,
                Validators.email as any,
                Validators.minLength(this.minEmail) as any,
                Validators.maxLength(this.maxEmail) as any
            ]),
        });
    }
}
