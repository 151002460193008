import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { Auth, getIdToken } from '@angular/fire/auth';
import { first, from, Observable, switchMap, throwError } from 'rxjs';
import { environment } from "../../../../environments/environment";
import { AuthService, CurrentUserInterface } from '../services/auth.service';
import md5 from 'md5';
import { BUILD, VERSION } from '../../../../environments/version';
import { FingerPrintService } from '../services/finger-print.service';
import { DEBUG_MODE_KEY } from '../components/app.types';
import { getNumber, RemoteConfig } from '@angular/fire/remote-config';
import { CookieService } from 'ngx-cookie-service';

export const TokenInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const authService = inject(AuthService);
    const auth = inject(Auth);
    const fingerPrintService = inject(FingerPrintService);
    const remoteConfig = inject(RemoteConfig);
    const cookieService = inject(CookieService);

    const addCustomHeaders = (req: HttpRequest<any>): HttpRequest<any> => {
        const now = new Date();
        const timestamp = Math.floor(now.getTime() / 1000);
        const customHeaders = {
            'X-Client-App': 'admin_panel',
            'X-Client-Build': BUILD,
            'X-Client-Version': VERSION,
            'X-Date': now.toISOString(),
            'X-Boo-Sign': md5(
                fingerPrintService.getVisitorId()
                    ? timestamp + ' ' + fingerPrintService.getVisitorId()
                    : (1000 * Math.floor(timestamp / 1000)).toString()
            ),
        };

        const debugModeLevel = getDebugModeLevel();

        if (debugModeLevel > 0) {
            customHeaders['X-Debug'] = String(debugModeLevel);
        }

        if (fingerPrintService.getVisitorId()) {
            customHeaders['X-Client-Id'] = fingerPrintService.getVisitorId();
        }

        if (getApiVersion(req.url)) {
            customHeaders['Accept-Version'] = getApiVersion(req.url);
        }

        if (getApiModule(req.url)) {
            customHeaders['X-Api'] = getApiModule(req.url);
        }


        return req.clone({
            setHeaders: customHeaders,
        });
    }

    const getApiVersion = (url: string): string => {
        const apiVersionPath = url.match(/\/v\d\//);

        if (!apiVersionPath?.length) {
            return '';
        }

        return apiVersionPath[0].match(/v\d/)[0];
    }

    const getApiModule = (url: string): string => {
        let apiModule = '';

        switch (true) {
            case url.includes('/admin/api/'):
                apiModule = 'AdminAPI';
                break;
        }

        return apiModule;
    }

    const getDebugModeLevel = (): number => {
        const isDebugModeCookie = cookieService.check(DEBUG_MODE_KEY);
        const debugModeCookie = cookieService.get(DEBUG_MODE_KEY);
        const debugModeLocalStorage = window.localStorage.getItem(DEBUG_MODE_KEY);
        let level = getNumber(remoteConfig, 'flag_debug_mode_admin');

        switch (true) {
            case isDebugModeCookie:
                level = /^\d+$/.test(debugModeCookie) ? Number(debugModeCookie) : 0;
                break;
            case debugModeLocalStorage !== null:
                level = /^\d+$/.test(debugModeLocalStorage)
                    ? Number(debugModeLocalStorage)
                    : 0;
                break;
        }

        return level;
    }

    request = addCustomHeaders(request);
    const addAuthToken = (accessToken?: string) => {
        if (!accessToken) {
            authService.logout();
            return request;
        }
        return request.clone({
            headers: request.headers.set('Authorization', `Bearer ${accessToken}`),
        });
    };

    return authService.currentUser$.pipe(
        switchMap((user) => {
            if (!request.headers.get('Authorization') && !request.url.includes(environment.ESB_SERVER)) {
                const userFirebase = auth.currentUser?.toJSON() as CurrentUserInterface;
                const TIME_BUFFER = 1 * 60 * 1000; // 1 min;
                if (userFirebase && (!user?.stsTokenManager?.accessToken || user?.stsTokenManager?.expirationTime <= (Date.now() - TIME_BUFFER))) {
                    return from(getIdToken(auth.currentUser)).pipe(
                        first(),
                        switchMap((newAccessToken) => {
                            authService.currentUserSource.next(auth.currentUser?.toJSON() as CurrentUserInterface);
                            if (newAccessToken) {
                                return next(addAuthToken(newAccessToken));
                            } else {
                                authService.logout();
                                return throwError('');
                            }
                        })
                    );
                } else {
                    return next(addAuthToken(userFirebase?.stsTokenManager?.accessToken || user?.stsTokenManager?.accessToken));
                }
            }
            return next(request);
        })
    );
};
