import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Auth } from "@angular/fire/auth";
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "../../../../shared/application/services/toastr.service";
import { CoreService } from '../../services/core.service';
import { Notification, ExportService, ExportStatusEnum } from '../../services/export.service';


@Component({
    selector: 'app-export-results',
    templateUrl: './export-results.component.html',
    styleUrls: ['./export-results.component.scss'],
    providers: [NgbDropdown],
})
export class ExportResultsComponent implements OnInit, OnChanges
{
    @ViewChild('exportResultsDropdown', {static: true}) dropdown: NgbDropdown;
    protected readonly ExportStatusEnum = ExportStatusEnum;
    protected readonly INFO_LIMIT = 500;

    constructor(
        public exportService: ExportService,
        public coreService: CoreService,
        public toastrService: ToastrService,
        private auth: Auth,
    )
    {
        this.exportService.uid = this.auth?.currentUser?.uid;
    }

    ngOnInit()
    {
        setTimeout(() => {
            this.exportService.init()
        });
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (
            changes.logged !== undefined &&
            changes.logged.previousValue !== undefined &&
            changes.logged.currentValue !== changes.logged.previousValue
        ) {
            this.exportService.uid = this.auth.currentUser.uid;
        }
    }

    trackByFunction = (index, item: Notification): string => item.jobId;

    download = (path: string) => this.coreService.downloadFile(path, '')
    remove = (data: Notification) => this.exportService.remove(data)
    isFinalStatus = (status: ExportStatusEnum) => ExportService.FINAL_STATUSES.includes(status);
    getInfoTitle = (data: any) => {
        if (!data && data !== 0) return '';
        if (typeof data === "object") return JSON.stringify(data);
        const result = String(data)
        if (result.length <= this.INFO_LIMIT) return result;
        return result.slice(0, this.INFO_LIMIT) + '...';
    }
}
