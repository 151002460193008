import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { CurrentUserResponse } from '../../../infrastructure/response/current-user.response';

@Component({
    selector: 'app-profile-panel',
    templateUrl: './profile-panel.component.html',
    styleUrls: ['./profile-panel.component.scss']
})
export class ProfilePanelComponent
{
    @Output() closeProfile = new EventEmitter();
    @Input() currentUser: CurrentUserResponse;
    timeZone: string;
    timeOffset: string;

    constructor(private authService: AuthService)
    {
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.timeOffset = new Date().toString().match(/([-+][0-9]+)\s/)[1];
    }

    public logout(): void
    {
        this.close();
        this.authService.logout();
    }

    public close(): void
    {
        this.closeProfile.emit();
    }
}
