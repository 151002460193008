import { Injectable } from '@angular/core';
import { Auth, getIdTokenResult, onAuthStateChanged, User } from "@angular/fire/auth";
import { getString, RemoteConfig } from "@angular/fire/remote-config";
import { ActivatedRouteSnapshot, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from '@angular/router';
import { from, mergeMap, ReplaySubject } from "rxjs";
import { take } from "rxjs/operators";
import { DEFAULT_ROUTE } from "../../../../constants/default-route";
import { AuthService, CurrentUserInterface } from "../services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AccessGroupGuard
{
    private firebaseInit = new ReplaySubject(1);

    constructor(
        private auth: Auth,
        private router: Router,
        private remoteConfig: RemoteConfig,
        private authService: AuthService,
    )
    {
        onAuthStateChanged(
            this.auth,
            (user: User) => {
                this.authService.currentUserSource.next(user as CurrentUserInterface);
                this.firebaseInit.next(true);
            },
            () => this.firebaseInit.next(true),
            () => this.firebaseInit.next(true)
        );
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): MaybeAsync<GuardResult>
    {
        return this.firebaseInit.pipe(
            take(1),
            mergeMap(() =>
                from(
                    (async () => {
                        const redirectToAccessDenied = () => this.router.navigate([DEFAULT_ROUTE.accessDenied]);

                        try {
                            const {claims} = await getIdTokenResult(this.auth.currentUser);
                            const useAccessGroup = 'accessGroup' in claims;
                            if (!useAccessGroup) return true;
                            if (!claims.accessGroup) {
                                await redirectToAccessDenied();
                                return false;
                            }
                            const accessConfig = this.getAccessGroupConfig();
                            const hasAccess = accessConfig?.[claims?.accessGroup as string]?.routes.includes(state.url);

                            if (!hasAccess) await redirectToAccessDenied();
                            return hasAccess;
                        } catch (e) {
                            await this.authService.logout();
                            return false;
                        }
                    })()
                )
            )
        );
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): MaybeAsync<GuardResult>
    {
        return this.canActivate(childRoute, state);
    }

    private getAccessGroupConfig()
    {
        const dataString = getString(this.remoteConfig, 'access_group_config');
        if (!dataString) return;
        return JSON.parse(dataString);
    }
}
